<template>
  <LoaderOverlay />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <app-notifications
    :speed="500"
    position="top right"
  />
</template>
<script lang="ts" setup>
import LoaderOverlay from "~/components/LoaderOverlay.vue";
</script>
<style lang="scss">
.vue-notification-group {
  // styling
  margin: 15px;
}
</style>
